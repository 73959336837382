
import AppList from "@/components/AppList.vue";
import AppLogo from "@/components/commons/AppLogo.vue";
import Vue from "vue";
import Component from "vue-class-component";

@Component({
  components: {
    AppList,
    AppLogo,
  },
})
export default class AppListView extends Vue {}
