
import Vue from "vue";
import { mapGetters } from "vuex";
import LoginComponent from "../components/LoginComponent.vue";

export default Vue.extend({
  name: "LoginView",
  components: {
    LoginComponent,
  },
  computed: {
    ...mapGetters("UserModule", ["user"]),
  },
});
