
import { Component, Vue } from "vue-property-decorator";
import { mapActions, mapGetters } from "vuex";

@Component({
  computed: {
    ...mapGetters(["helperDialog", "helper"]),
  },
  methods: {
    ...mapActions(["toggleHelperCenter", "setHelperDialog"]),
  },
})
export default class HelperCenter extends Vue {
  helperDialog!: boolean;
  helper!: Record<string, string>;
  toggleHelperCenter!: () => Promise<void>;
  setHelperDialog!: (value: boolean) => void;

  set dialog(value: boolean) {
    this.setHelperDialog(value);
  }
  get dialog(): boolean {
    return this.helperDialog;
  }
}
