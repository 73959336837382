
import { Component, Vue } from "vue-property-decorator";
import { mapActions, mapGetters } from "vuex";

@Component({
  computed: {
    ...mapGetters(["notificationDialog", "notifications"]),
  },
  methods: {
    ...mapActions([
      "toggleNotificationCenter",
      "setNotificationDialog",
      "clearNotifications",
    ]),
  },
})
export default class NotificationCenter extends Vue {
  notificationDialog!: boolean;
  toggleNotificationCenter!: () => Promise<void>;
  setNotificationDialog!: (value: boolean) => void;

  set dialog(value: boolean) {
    this.setNotificationDialog(value);
  }
  get dialog(): boolean {
    return this.notificationDialog;
  }
}
