
import { labLogos, logos } from "@/components/commons/logos";
import "vue-class-component/hooks";
import { Component, Vue } from "vue-property-decorator";

@Component
export default class AppLogo extends Vue {
  logos = logos;
  labLogos = labLogos;
}
