
import EnergyCookingFuels from "@/components/reference_data/EnergyCookingFuels.vue";
import EnergyCookingStoves from "@/components/reference_data/EnergyCookingStoves.vue";
import { Component, Vue } from "vue-property-decorator";
import { mapActions, mapGetters } from "vuex";

@Component({
  computed: {
    ...mapGetters(["referenceDataDrawer"]),
  },
  methods: {
    ...mapActions(["toggleReferenceData", "setReferenceDataDrawer"]),
  },
  components: {
    EnergyCookingStoves,
    EnergyCookingFuels,
  },
})
/** ProjectList */
export default class App extends Vue {
  referenceDataDrawer!: boolean;
  toggleReferenceData!: () => Promise<void>;
  setReferenceDataDrawer!: (value: boolean) => void;

  set drawer(value: boolean) {
    this.setReferenceDataDrawer(value);
  }
  get drawer(): boolean {
    return this.referenceDataDrawer;
  }
  readonly menuItems: MenuSurveyItem[] = [
    {
      tab: "Stoves",
      componentName: "EnergyCookingStoves",
    },
    {
      tab: "Fuels",
      componentName: "EnergyCookingFuels",
    },
  ];
  tab = 1;
}

interface MenuSurveyItem {
  tab: string;
  componentName: string;
}
